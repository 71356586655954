import React from 'react';

import Layout from '../../components/layout';
import SEO from '../../components/seo';

const Success = () => (
  <Layout>
    <SEO title="Thanks!" />
    <h1 className="mt-10 font-bold text-gray-500 uppercase">
      Successfully sent
    </h1>
    <p className="mt-2">
      Thanks for reaching out! I'll get back to you as soon as I can.
    </p>
  </Layout>
);

export default Success;
